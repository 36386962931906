import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div className="post">
      <h2 className="title">Not found</h2>
      <p>Nothing to see here.</p>
    </div>
  </Layout>
)

export default NotFoundPage
